define("discourse/plugins/discourse-presence/discourse/components/composer-presence-display", ["exports", "@ember/component", "@ember/runloop", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/discourse-presence/discourse/lib/presence", "discourse/models/composer"], function (_exports, _component, _runloop, _computed, _service, _decorators, _presence, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("model.topic.id"), _dec2 = (0, _decorators.default)("model.topic.id"), _dec3 = (0, _decorators.on)("didInsertElement"), _dec4 = (0, _decorators.default)("model.post.id", "editingUsers.@each.last_seen", "users.@each.last_seen", "model.action"), _dec5 = (0, _decorators.observes)("model.reply", "model.title"), _dec6 = (0, _decorators.observes)("model.whisper"), _dec7 = (0, _decorators.observes)("model.action", "model.topic.id"), _dec8 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    // Passed in variables
    presenceManager: (0, _service.inject)(),
    users(topicId) {
      return this.presenceManager.users(topicId);
    },
    editingUsers(topicId) {
      return this.presenceManager.editingUsers(topicId);
    },
    isReply: (0, _computed.equal)("model.action", _composer.REPLY),
    subscribe() {
      this.presenceManager.subscribe(this.get("model.topic.id"), _presence.COMPOSER_TYPE);
    },
    presenceUsers(postId, editingUsers, users, action) {
      if (action === _composer.EDIT) {
        return editingUsers.filterBy("post_id", postId);
      } else if (action === _composer.REPLY) {
        return users;
      }
      return [];
    },
    shouldDisplay: (0, _computed.gt)("presenceUsers.length", 0),
    typing() {
      (0, _runloop.throttle)(this, this._typing, _presence.KEEP_ALIVE_DURATION_SECONDS * 1000);
    },
    _typing() {
      const action = this.get("model.action");
      if (action !== _composer.REPLY && action !== _composer.EDIT || !this.get("model.composerOpened")) {
        return;
      }
      let data = {
        topicId: this.get("model.topic.id"),
        state: action === _composer.EDIT ? _presence.EDITING : _presence.REPLYING,
        whisper: this.get("model.whisper"),
        postId: this.get("model.post.id"),
        presenceStaffOnly: this.get("model._presenceStaffOnly")
      };
      this._prevPublishData = data;
      this._throttle = this.presenceManager.publish(data.topicId, data.state, data.whisper, data.postId, data.presenceStaffOnly);
    },
    cancelThrottle() {
      this._cancelThrottle();
    },
    composerState() {
      if (this._prevPublishData) {
        this.presenceManager.publish(this._prevPublishData.topicId, _presence.CLOSED, this._prevPublishData.whisper, this._prevPublishData.postId);
        this._prevPublishData = null;
      }
    },
    closeComposer() {
      this._cancelThrottle();
      this._prevPublishData = null;
      this.presenceManager.cleanUpPresence(_presence.COMPOSER_TYPE);
    },
    _cancelThrottle() {
      if (this._throttle) {
        (0, _runloop.cancel)(this._throttle);
        this._throttle = null;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "users", [_dec], Object.getOwnPropertyDescriptor(_obj, "users"), _obj), _applyDecoratedDescriptor(_obj, "editingUsers", [_dec2], Object.getOwnPropertyDescriptor(_obj, "editingUsers"), _obj), _applyDecoratedDescriptor(_obj, "subscribe", [_dec3], Object.getOwnPropertyDescriptor(_obj, "subscribe"), _obj), _applyDecoratedDescriptor(_obj, "presenceUsers", [_dec4], Object.getOwnPropertyDescriptor(_obj, "presenceUsers"), _obj), _applyDecoratedDescriptor(_obj, "typing", [_dec5], Object.getOwnPropertyDescriptor(_obj, "typing"), _obj), _applyDecoratedDescriptor(_obj, "cancelThrottle", [_dec6], Object.getOwnPropertyDescriptor(_obj, "cancelThrottle"), _obj), _applyDecoratedDescriptor(_obj, "composerState", [_dec7], Object.getOwnPropertyDescriptor(_obj, "composerState"), _obj), _applyDecoratedDescriptor(_obj, "closeComposer", [_dec8], Object.getOwnPropertyDescriptor(_obj, "closeComposer"), _obj)), _obj)));
});