define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/discourse-presence/discourse/lib/presence"], function (_exports, _component, _computed, _service, _decorators, _presence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("topic.id"), _dec2 = (0, _decorators.on)("didInsertElement"), _dec3 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    topic: null,
    presenceManager: (0, _service.inject)(),
    users(topicId) {
      return this.presenceManager.users(topicId);
    },
    shouldDisplay: (0, _computed.gt)("users.length", 0),
    subscribe() {
      this.presenceManager.subscribe(this.get("topic.id"), _presence.TOPIC_TYPE);
    },
    _destroyed() {
      this.presenceManager.unsubscribe(this.get("topic.id"), _presence.TOPIC_TYPE);
    }
  }, (_applyDecoratedDescriptor(_obj, "users", [_dec], Object.getOwnPropertyDescriptor(_obj, "users"), _obj), _applyDecoratedDescriptor(_obj, "subscribe", [_dec2], Object.getOwnPropertyDescriptor(_obj, "subscribe"), _obj), _applyDecoratedDescriptor(_obj, "_destroyed", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_destroyed"), _obj)), _obj)));
});